import { FormResponse } from "@/forms/FormResponse";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import SystemKeys from "@/common/utils/SystemKeys";
import { Plant, PlantPart } from "../services/PlantService";
import { ICreatePlantPartPresenter } from "../controllers/CreatePlantPartController";
import { CreatePlantPartViewModel } from "../vms/CreatePlantPartViewModel";
import { PlantPartForm } from "../forms/PlantPartForm";
import { PartStorageHandler } from "@/storage/storageHandlers/PartStorageHandler";
import { SelectionItem } from "@/forms/ViewModelFormTypes";

export class CreatePlantPartPresenter implements ICreatePlantPartPresenter {
  public plantPartForm: PlantPartForm;

  public editMode: boolean;

  public constructor(
    private vm: CreatePlantPartViewModel,
    plant: Plant,
    plantPart: PlantPart,
    public isInstallationPart: boolean
  ) {
    this.plantPartForm = new PlantPartForm(this.vm, this.setPlantPartFormValid);

    this.vm.plant = plant;
    this.editMode = !!plantPart;
    this.initSelections(plant);

    if (!!plantPart) {
      this.initPlantPartFromPlantPart(plantPart);
    }
  }

  public setPlantPartFormValid(context: any, valid: boolean) {
    context.updatePlantButtonDisabled = !valid;
  }

  public set visibleView(visible: boolean) {
    this.vm.visibleView = visible;
    if (!visible) {
      setTimeout(() => (this.vm.goBack = true), 300);
    }
  }

  public set updatePlantResponse(response: FormResponse<string>) {
    this.vm.updatePlantRequest = response;

    if (!response.loading && !response.error) {
      if (!this.editMode) {
        this.vm.plant.plantParts.push(this.plantPart);
      }
      this.vm.updatedPlant = response.data;
    }
  }

  public get plant(): Plant {
    return this.vm.plant;
  }

  public get plantPart(): PlantPart {
    return {
      parameterId: this.plantPartForm.getFieldValue("part"),
      amount: parseInt(this.plantPartForm.getFieldValue("amount"), 10),
      description: this.plantPartForm.getFieldValue("description"),
      index: -1
    };
  }

  private initSelections(plant: Plant) {
    if (this.isInstallationPart) {
      this.initSelectionHelper(
        PartStorageHandler.getAllPlantInstallationParts(),
        plant.plantInstallationParts
      );
    } else {
      this.initSelectionHelper(
        PartStorageHandler.getAllPlantParts(),
        plant.plantParts
      );
    }
  }

  private initSelectionHelper(items: SelectionItem[], plantParts: PlantPart[]) {
    this.vm.part.items = items;

    if (!this.editMode) {
      for (const part of plantParts) {
        const index = this.vm.part.items.findIndex(
          el => el.value === part.parameterId
        );
        if (index >= 0) {
          this.vm.part.items.splice(index, 1);
        }
      }
    }
  }

  private initPlantPartFromPlantPart(plantPart: PlantPart) {
    this.plantPartForm.setFieldValue("part", plantPart.parameterId);
    this.plantPartForm.setFieldValue("amount", plantPart.amount.toString());
    this.plantPartForm.setFieldValue("description", plantPart.description);
  }
}
