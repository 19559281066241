



















































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import RequestButton from "@/components/basic/RequestButton.vue";

import { LocalStorage } from "../../storage/LocalStorage";

import { CreatePlantPartViewModel } from "@/plant/vms/CreatePlantPartViewModel";
import { CreatePlantPartPresenter } from "@/plant/presenters/CreatePlantPartPresenter";
import { CreatePlantPartController } from "@/plant/controllers/CreatePlantPartController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { PlantGraphQLService } from "../services/PlantGraphQLService";
import { PlantPart, Plant } from "../services/PlantService";

@Component<CreatePlantPartView>({
  components: {
    Card,
    Container,
    Column,
    Row,
    RequestButton
  },
  props: {
    plant: {},
    part: {},
    isInstallationPart: Boolean
  },
  watch: {
    "vm.goBack"() {
      this.vm.visibleView = false;
      this.$emit("closed");
      this.$destroy();
    },
    "vm.updatedPlant"() {
      this.vm.visibleView = false;
      this.$emit("finished");
      this.$destroy();
    }
  }
})
export default class CreatePlantPartView extends Vue {
  private vm = new CreatePlantPartViewModel();
  private plant!: Plant;
  private part!: PlantPart;
  private isInstallationPart!: boolean;

  private get controller(): CreatePlantPartController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreatePlantPartController(
      new CreatePlantPartPresenter(
        this.vm,
        this.plant,
        this.part,
        this.isInstallationPart
      ),
      new PlantGraphQLService(connection)
    );
  }

  private mounted() {
    this.controller.mounted();
  }
}
