import { PlantPartForm } from "../forms/PlantPartForm";
import { FormResponse } from "@/forms/FormResponse";
import { PlantPart, Plant, IPlantService } from "../services/PlantService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";

export class CreatePlantPartController {
  public constructor(
    private presenter: ICreatePlantPartPresenter,
    private plantService: IPlantService
  ) {}

  public mounted() {
    this.presenter.visibleView = true;
  }

  public closeButtonClicked(): void {
    this.presenter.visibleView = false;
  }

  public partSelected(plantPart: string): void {
    this.presenter.plantPartForm.setFieldValue("part", plantPart);
  }

  public amountChanged(value: string): void {
    this.presenter.plantPartForm.setFieldValue("amount", value);
  }

  public descriptionChanged(description: string): void {
    this.presenter.plantPartForm.setFieldValue("description", description);
  }

  public updatePlantButtonClicked() {
    let request;
    if (this.presenter.isInstallationPart) {
      request = this.plantService.addPlantInstallationPart(
        this.presenter.plant,
        this.presenter.plantPart
      );
    } else {
      request = this.plantService.addPlantPart(
        this.presenter.plant,
        this.presenter.plantPart
      );
    }
    FormRequestHandler.handle(
      request,
      response => (this.presenter.updatePlantResponse = response),
      "update-plant-failed"
    );
  }
}

export interface ICreatePlantPartPresenter {
  plant: Plant;
  visibleView: boolean;

  plantPartForm: PlantPartForm;

  updatePlantResponse: FormResponse<string>;
  plantPart: PlantPart;
  isInstallationPart: boolean;
}
