import {
  Selection,
  Textfield,
  Request
} from "@/forms/ViewModelFormTypes";

export class CreatePlantPartViewModel {
  public plant: any = {};
  public visibleView: boolean = false;
  public goBack: boolean = false;
  public updatedPlant: string = "";

  public updatePlantRequest: Request = { loading: false, error: "" };

  public part: Selection = { selected: "", error: "", items: [] };
  public amount: Textfield = { value: "", error: "" };
  public description: Textfield = { value: "", error: "" };
}
