import { Form, FormFieldType } from "@/forms/Form";

export class PlantPartForm extends Form {
  protected definition = {
    part: { type: FormFieldType.Selection, required: true },
    amount: {
      type: FormFieldType.Number,
      required: true,
      greaterThan: 0
    },
    description: { type: FormFieldType.Text, required: false },
  };
}
